@import "../../../../../styles/mixins.scss";

.submitButton {
  margin-top: 20px;
}

@include tablet {
  .submitButton {
    width: unset;
    padding-top: 0;
    padding-right: 80px;
    padding-bottom: 0;
    padding-left: 80px;
  }
}
