@import "../../../styles/variables.scss";

.button {
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;

  &_wrapper {
    width: 32px;
    height: 32px;
  }

  &:hover:not(&_disabled) {
    background-color: $background-color;

    .icon {
      color: $text-primary-color;
    }
  }

  &_disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.icon {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  color: $element-background-secondary;
  transition: all 0.3s ease-in-out;
}
